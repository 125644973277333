<script>
import {defineComponent} from "vue"
import {toLocalString} from "@/lib/dateTimeUtilities"
import {leadProbabilities} from "@/lib/constants"

export default defineComponent({
  name: "LeadsDashboard",
  permissions: ["Read Leads", "Read Quotes"],
  methods: {
    loadLeads() {
      return this.$BwfApi.get("leads").then(response => {
        if (response) {
          this.leads = response
        }
      })
    },
    loadQuotes() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end, "detailed": true}
      this.$WfmApi.get("quote.api/list", query).then(response => {
        if (response.Quotes) {
          this.quotes = this.$ensureArray(response.Quotes.Quote)
        }
      })
    },
    findQuote(quoteUuid) {
      return this.quotes.find(quote => quote.UUID === quoteUuid)
    },
  },
  computed: {
    leadProbabilityWeights() {
      return {
        low: leadProbabilities.find(p => p.value === "low").weight / 100,
        medium: leadProbabilities.find(p => p.value === "medium").weight / 100,
        high: leadProbabilities.find(p => p.value === "high").weight / 100,
      }
    },
    newLeads() {
      const leads = this.leads.filter(lead => lead.stage === "new")
      return {
        number: leads.length,
      }
    },
    scopeLeads() {
      const leads = this.leads.filter(lead => lead.stage === "scope")
      return {
        number: leads.length,
      }
    },
    bidLeads() {
      const leads = this.leads.filter(lead => lead.stage === "bid")
      return {
        number: leads.length,
      }
    },
    inactiveLeads() {
      const leads = this.leads.filter(lead => lead.stage === "inactive")
      return {
        number: leads.length,
      }
    },
    inProgressLeads() {
      const data = {
        low: {
          number: 0,
          value: 0,
        },
        medium: {
          number: 0,
          value: 0,
        },
        high: {
          number: 0,
          value: 0,
        },
        weighted: {
          number: 0,
          value: 0,
        },
      }

      // ensure all leads have a probability
      this.leads.forEach(lead => {
        if (!lead.probability) {
          lead.probability = "medium"
        }
      })

      console.log(this.leads)

      const quoted = this.leads.filter(lead => ['new', 'scope', 'bid'].includes(lead.stage))
      const low = quoted.filter(lead => lead.probability === "low")
      const medium = quoted.filter(lead => lead.probability === "medium")
      const high = quoted.filter(lead => lead.probability === "high")

      const levels = {low, medium, high}
      for (const level in levels) {
        for (const lead of levels[level]) {
          const quote = this.findQuote(lead.quote_uuid)
          data[level].number += 1
          if (quote) {
            data[level].value += parseFloat(quote.Amount)
          } else if (lead.budget) {
            data[level].value += parseFloat(lead.budget)
          }
        }
      }

      data.weighted.number = data.low.number * this.leadProbabilityWeights.low +
          data.medium.number * this.leadProbabilityWeights.medium +
          data.high.number * this.leadProbabilityWeights.high

      data.weighted.value = data.low.value * this.leadProbabilityWeights.low +
          data.medium.value * this.leadProbabilityWeights.medium +
          data.high.value * this.leadProbabilityWeights.high

      return data
    },
  },
  mounted() {
    this.loadLeads()
    this.loadQuotes()
  },
  data: () => ({
    quotes: [],
    leads: [],
  }),
})
</script>

<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
      <tr>
        <th class="text-center"></th>
        <th class="text-right">New</th>
        <th class="text-right">Scope</th>
        <th class="text-right">Bid</th>
        <th class="text-right">Inactive</th>
        <th class="text-center" colspan="3">Active</th>
        <th></th>
      </tr>

      <tr>
        <th class="text-right"></th>
        <th class="text-right"></th>
        <th class="text-right"></th>
        <th class="text-right"></th>
        <th class="text-right"></th>
        <th class="text-right">Low</th>
        <th class="text-right">Medium</th>
        <th class="text-right">High</th>
        <th class="text-right">Weighted Total</th>
      </tr>
      <tr>
        <th class="text-right">Number</th>
        <td class="text-right">{{ newLeads.number }}</td>
        <td class="text-right">{{ scopeLeads.number }}</td>
        <td class="text-right">{{ inactiveLeads.number }}</td>
        <td class="text-right">{{ bidLeads.number }}</td>
        <td class="text-right">{{ inProgressLeads.low.number }}</td>
        <td class="text-right">{{ inProgressLeads.medium.number }}</td>
        <td class="text-right">{{ inProgressLeads.high.number }}</td>
        <td class="text-right">{{ inProgressLeads.weighted.number }}</td>
      </tr>

      <tr>
        <th class="text-right">Value</th>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right">{{ inProgressLeads.low.value | formatCurrency }}</td>
        <td class="text-right">{{ inProgressLeads.medium.value | formatCurrency }}</td>
        <td class="text-right">{{ inProgressLeads.high.value | formatCurrency }}</td>
        <td class="text-right">{{ inProgressLeads.weighted.value | formatCurrency }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style scoped>

</style>