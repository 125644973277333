<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="false"
                loaderType="list-item">
    <h3>{{ soFarThisMonth }} hours used so far this month</h3>
    <v-switch v-model="showDetail" label="Show Detail" class="mb-2"
              v-if="$access.permissions.includes('Modify Capacity Plan')"></v-switch>


    <div class="save-area mt-2">
      <v-alert type="info" v-if="isChanged" class="text-right">
        <p>Changes have been made</p>
        <v-btn color="accent" @click="save" class="ma-3">Save now</v-btn>
        <v-btn color="warning" @click="reset" class="ma-3">Reset all</v-btn>
      </v-alert>
    </div>

    <BarScroll :key="jobId" :jobId="jobId" :services="jobServices" v-model="jobPlannedCapacityData"
               :timeUsedThisJob="timeUsedThisJob" :forecastData="jobPlannedForecastData"
               :expectedDueDate="shortenDate(dueDate)" :originalDueDate="shortenDate(originalDueDate)"
               :basic="!showDetail" @forecastChanged="updateForecast" @changed="barScrollChanged"
               ref="barScroll"></BarScroll>

  </BfwComponent>
</template>

<script>
import BarScroll from "@/components/toolkit/BarScroll.vue"
import {formatDate, getDateOfISOWeek, getWeekFraction} from "@/lib/dateTimeUtilities"
import BfwComponent from "@/components/BwfComponent.vue"

export default {
  name: "CapacityPlannerStandalone",
  permissions: [...BarScroll.permissions,
    "Read Services", "Modify Capacity Plan", "Read Job", "Read Capacity", "Modify Job", "Read Custom Field",
    "Read Capacity Plan", "Read Time"],
  props: ["jobId", "originalDueDate"], // job is an object
  components: {
    BfwComponent,
    BarScroll,
  },
  watch: {
    jobId: function (newVal) {
      if (this.jobId) {
        this.jobChanged(newVal)
      }
    },
  },
  computed: {
    isReady: function () {
      return this.jobLoaded && this.bwfJobLoaded && this.jobCapacityLoaded && this.jobScheduledLoaded && this.jobTimeUsedLoaded
    },
    soFarThisMonth() {
      const now = new Date()
      const monthStr = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0")
      let total = 0
      if (monthStr in this.timeUsedThisJob) {
        for (const serviceId in this.timeUsedThisJob[monthStr]) {
          total += this.timeUsedThisJob[monthStr][serviceId]
        }
      }
      return Math.round(total)
    },
  },
  methods: {
    jobChanged(jobId) {
      this.$WfmApi.get("job.api/get/" + jobId).then(response => {
        this.isChanged = false
        this.jobLoaded = true
        this.job = response.Job
        this.dueDate = this.job.DueDate
        this.prepareServices()
        this.loadBwfJob()
        this.loadJobCapacityData()
        this.loadJobTimeUsed()
        this.loadJobScheduledData()
        this.initializeData()
      })
    },
    updateForecast(forecastData) {
      this.jobPlannedForecastData = forecastData
    },
    save() {
      const toSaveLabour = {}
      for (const monthStr in this.jobPlannedCapacityData) {
        if (!this.isHistorical(monthStr)) {
          toSaveLabour[monthStr] = this.jobPlannedCapacityData[monthStr]
        }
      }

      const toSaveForecast = {}
      for (const monthStr in this.jobPlannedForecastData) {
        if (!this.isHistorical(monthStr)) {
          toSaveForecast[monthStr] = this.jobPlannedForecastData[monthStr]
        }
      }

      this.$BwfApi.post("capacity-plan/job", {
        job_id: this.job.ID,
        plan: toSaveLabour,
        forecast: toSaveForecast
      }).then(() => {
        this.isChanged = false
        this.saveJob()
        this.$notify.toast("Saved")
      })
    },
    saveJob: function () {
      const data = {
        Job: {
          ID: this.job.ID,
          Name: this.job.Name,
          StartDate: this.job.StartDate.substr(0, 10).replace(/-/g, ""),
          DueDate: this.dueDate.substr(0, 10).replace(/-/g, ""),
          ClientNumber: this.job.ClientOrderNumber
        }
      }
      this.$WfmApi.put("job.api/update", data).then(() => {
        this.$notify.toast("Success")
      })
    },
    reset() {
      this.$refs.barScroll.reset()
      this.isChanged = false
    },
    isHistorical(monthStr) {
      const parts = monthStr.split("-")
      const dataYear = parseInt(parts[0])
      const dataMonth = parseInt(parts[1])
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth() + 1
      const c = currentYear * 12 + currentMonth
      const d = dataYear * 12 + dataMonth
      return d < c
    },
    loadBwfJob() {
      this.bwfJobLoaded = false
      this.$BwfApi.get("job", {job_id: this.jobId}).then((response) => {
        this.bwfJob = response
        this.bwfJobLoaded = true
      })
    },
    initializeData() {
      // services template
      const servicesTemplate = {}
      for (const service of this.jobServices) {
        servicesTemplate[service.service_id] = 0
      }

      // build obj of months
      const startYear = parseInt(this.job.StartDate.substring(0, 4))
      const startMonth = parseInt(this.job.StartDate.substring(5, 7))
      const start = startYear * 12 + startMonth

      const endYear = parseInt(this.dueDate.substring(0, 4))
      const endMonth = parseInt(this.dueDate.substring(5, 7))
      const end = endYear * 12 + endMonth

      const labourMonths = {}
      const forecastMonths = {}
      let month = startMonth
      let year = startYear
      for (let i = start; i <= end; i++) {
        if (month === 13) {
          month = 1
          year += 1
        }
        const monthStr = year.toString() + "-" + month.toString().padStart(2, "0")
        labourMonths[monthStr] = servicesTemplate
        forecastMonths[monthStr] = {labour: 0, materials: 0}
        month += 1
      }
      this.jobPlannedCapacityData = labourMonths
      this.jobPlannedForecastData = forecastMonths
    },
    loadJobCapacityData() {
      this.jobCapacityLoaded = false
      this.$BwfApi.get("capacity-plan/job", {job_id: this.job.ID}).then(response => {
        for (const monthString in response.labour) {
          for (const serviceId in response.labour[monthString]) {
            const month = {...this.jobPlannedCapacityData[monthString]}
            month[serviceId] = response.labour[monthString][serviceId]
            this.$set(this.jobPlannedCapacityData, monthString, month)
          }
        }

        for (const monthString in response.forecast) {
          const month = {...this.jobPlannedForecastData[monthString]}
          month['labour'] = response.forecast[monthString]['labour']
          month['materials'] = response.forecast[monthString]['materials']
          this.$set(this.jobPlannedForecastData, monthString, month)
        }

        this.jobCapacityLoaded = true
      })
    },
    loadJobScheduledData() {
      this.jobScheduledLoaded = false
      this.$BwfApi.get("staff-plan/job", {job_id: this.job.ID}).then(response => {
        const result = {}
        for (const task of response) {
          if (task.service_id === -1) { // Other jobs
            continue
          }
          const date = getDateOfISOWeek(task.week, task.year)
          const month = date.getMonth() + 1
          const fraction = getWeekFraction(month, task.week, task.year)
          const monthStr = task.year + "-" + month.toString().padStart(2, "0")
          if (!result[monthStr]) {
            result[monthStr] = {}
          }
          if (!result[monthStr][task.service_id]) {
            result[monthStr][task.service_id] = 0
          }
          result[monthStr][task.service_id] += task.allocated_hours * fraction

          // if the week crosses a month boundary, add the fraction to the next month as well
          date.setDate(date.getDate() + 6)
          const eowMonth = date.getMonth() + 1
          if (eowMonth !== month) {
            const fraction = getWeekFraction(eowMonth, task.week, task.year)
            const monthStr = task.year + "-" + eowMonth.toString().padStart(2, "0")
            if (!result[monthStr]) {
              result[monthStr] = {}
            }
            if (!result[monthStr][task.service_id]) {
              result[monthStr][task.service_id] = 0
            }
            result[monthStr][task.service_id] += task.allocated_hours * fraction
          }

        }
        this.jobScheduledData = result
        this.jobScheduledLoaded = true
      })
    }    ,
    loadJobTimeUsed() { // load job time entries
      this.jobTimeUsedLoaded = false
      const now = new Date()
      const to = formatDate(now)
      now.setYear(now.getFullYear() - this.$WfmApi.lookback) // only look back 1 year for time entries
      const from = formatDate(now)
      this.$WfmApi.get("time.api/job/" + this.job.ID, {from: from, to: to}).then(response => {
        const timeUsedThisJob = {}
        if (response.Times) {
          const timeEntries = this.$ensureArray(response.Times.Time)
          for (const item of timeEntries) {
            const hours = parseInt(item.Minutes) / 60
            const monthStr = this.shortenDate(item.Date)
            const taskTypeUuid = this.findTaskTypeUuid(item.Task.UUID)
            if (!taskTypeUuid) {
              continue
            }

            const service = this.findService(taskTypeUuid)
            if (!service) {
              continue
            }

            if (!(monthStr in timeUsedThisJob)) {
              timeUsedThisJob[monthStr] = {}
            }
            if (!(service.service_id in timeUsedThisJob[monthStr])) {
              timeUsedThisJob[monthStr][service.service_id] = 0
            }
            timeUsedThisJob[monthStr][service.service_id] += hours
          }
        }
        this.timeUsedThisJob = timeUsedThisJob
        this.jobTimeUsedLoaded = true
      })
    }    ,
    shortenDate(dateStr) {
      if (!dateStr) return ""
      return dateStr.substring(0, 7)
    }    ,
    barScrollChanged(evt) {
      if (evt && evt.month) {
        //evt.month will be 1 or -1
        const d = new Date(this.dueDate)
        d.setMonth(d.getMonth() + evt.month)
        this.dueDate = formatDate(d, "-")
      }
      this.isChanged = true
    },
    prepareServices() {
      const services = {}
      if (this.job.Tasks) {
        const tasks = this.$ensureArray(this.job.Tasks.Task)
        tasks.forEach((task) => {
          const service = this.findService(task.TaskUUID)
          if (service) {
            if (!(task.TaskUUID in services)) {
              services[task.TaskUUID] = {
                name: service.name,
                service_id: service.service_id,
                color_idx: service.sequence,
                estimated: parseInt(task.EstimatedMinutes) / 60
              }
            } else {
              services[task.TaskUUID].estimated += parseInt(task.EstimatedMinutes) / 60
            }
          }
        })
      }

      this.jobServices = Object.values(services)
      this.jobServices.sort((a, b) => {
        return a.color_idx - b.color_idx
      })
    }
    ,
    findService(taskTypeUUID) {
      for (const service of this.services) {
        if (service.uuid === taskTypeUUID) {
          return service
        }
      }
      return false
    }
    ,
    findTaskTypeUuid(taskUUID) {
      if (!("Tasks" in this.job)) return false

      const tasks = this.$ensureArray(this.job.Tasks.Task)
      for (const task of tasks) {
        if (task.UUID === taskUUID) {
          return task.TaskUUID
        }
      }
      return false
    }
    ,
  },
  mounted() {
    const d = new Date()
    d.setMonth(d.getMonth() - 12) // only look back 12 months
    this.startYear = d.getFullYear()
    this.startMonth = d.getMonth() + 1
    const d2 = new Date()
    d2.setMonth(d.getMonth() + 12) // only look forward 12 months
    this.endYear = d2.getFullYear()
    this.endMonth = d2.getMonth() + 1

    this.$BwfApi.get("services", {enabled: true}).then(response => {
      this.services = response
    })

    if (this.jobId) {
      this.jobChanged(this.jobId)
    }
  }
  ,
  data() {
    return {
      title: "Capacity Planner",
      showTitle: true,
      editable: false,
      bwfJob: null,
      services: [],
      jobServices: [],
      jobPlannedCapacityData: {},
      jobPlannedForecastData: {},
      jobScheduledData: {},
      capacity: {},
      timeUsedThisJob: {},
      startYear: 0,
      startMonth: 0,
      endYear: 0,
      endMonth: 0,
      isChanged: false,
      jobLoaded: false,
      jobCapacityLoaded: false,
      jobScheduledLoaded: false,
      jobTimeUsedLoaded: false,
      dueDate: "",
      bwfJobLoaded: false,
      showDetail: false,
    }
  },
}

</script>

<style scoped>
.save-area {
  min-height: 150px;
}

.pop-button {
  position: absolute;
  right: 0;
  top: 0;
}
</style>