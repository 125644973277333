<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <div v-if="isReady">
      <v-row class="mt-0">

        <v-col>
          <v-text-field label="Search" append-icon="mdi-magnify" single-line hide-details
                        v-model="searchFilter"></v-text-field>
        </v-col>

        <v-col>
          <div class="strong">Created</div>
          <v-menu v-model="showFilterCreatedFromPopup" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filterCreatedFromDate" dense
                            label="From" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filterCreatedFromDate" @input="showFilterCreatedFromPopup = false"></v-date-picker>
          </v-menu>

          <v-menu v-model="showFilterCreatedToPopup" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filterCreatedToDate" dense
                            label="To" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filterCreatedToDate" @input="showFilterCreatedToPopup = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <div class="strong">Closed</div>
          <v-menu v-model="showFilterClosedFromPopup" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filterClosedFromDate" dense
                            label="From" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filterClosedFromDate" @input="showFilterClosedFromPopup = false"></v-date-picker>
          </v-menu>

          <v-menu v-model="showFilterClosedToPopup" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filterClosedToDate" dense
                            label="To" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filterClosedToDate" @input="showFilterClosedToPopup = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <v-select label="Stage" :items="leadStages" v-model="stageFilter" multiple dense
                    v-if="stage=== 'all'"></v-select>
          <v-select label="Owner" :items="staffOptions" v-model="ownerFilter" multiple dense></v-select>
          <v-select label="Client Type" :items="clientTypeOptions" v-model="clientTypeFilter" multiple dense></v-select>
        </v-col>
      </v-row>


      <v-data-table dense :headers="headers" :items="tableData" :items-per-page="1000" :item-class="getRowClass">
        <template v-slot:item.actions="{item}">
          <v-btn x-small fab color="primary" @click="editLead (item.lead)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </div>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {leadStages} from "@/lib/constants"
import {formatDate} from "@/lib/dateTimeUtilities"
import {getStaffList} from "@/lib/lookup"

export default {
  name: "LeadsList",
  permissions: ["Read Leads"],
  props: {
    stage: {
      type: String,
      default: "all"
    },
    filter: {
      type: Array,
      default: () => {
        []
      }
    }
  },
  emits: ["selected"],
  data() {
    return {
      title: "Leads Overview",
      showTitle: false,
      editable: false,
      isReady: false,
      settings: {},
      leads: [],
      staff: {},
      clients: {},
      filterCreatedFromDate: null,
      filterCreatedToDate: null,
      showFilterCreatedFromPopup: false,
      showFilterCreatedToPopup: false,
      filterClosedFromDate: null,
      filterClosedToDate: null,
      showFilterClosedFromPopup: false,
      showFilterClosedToPopup: false,
      showFilter: false,
      stageFilter: [],
      ownerFilter: [],
      clientTypeFilter: [],
      searchFilter: "",
      leadStages: leadStages,
      headers: [
        {text: "Title", value: "title"},
        {text: "Client", value: "client"},
        {text: "Owner", value: "owner"},
        {text: "Stage", value: "stage"},
        {text: "Client Type", value: "isProspect"},
        {text: "Created", value: "created"},
        {text: "Closed", value: "closed"},
        {text: "Actions", value: "actions"}
      ],
      clientTypeOptions: [
        {text: "Prospect", value: 1},
        {text: "Existing", value: 0},
      ]
    }
  },
  components: {
    BfwComponent
  },
  methods: {
    loadLeads() {
      return this.$BwfApi.get("leads").then((response) => {
        const leads = []
        for (const lead of response) {
          if (lead.stage === this.stage || this.stage === "all") {
            leads.push(lead)
          }
          this.leads = leads
        }
      })
    },
    loadStaff() {
      return new Promise((resolve, reject) => {
        getStaffList(this, (res) => {
          if (!res) {
            reject()
            return
          }

          this.staff = []
          for (const staff of res) {
            this.staff[staff.wfm_userid] = staff.name
          }
          resolve()
        })
      })
    },
    loadClients() {
      return this.$WfmApi.get("client.api/list").then(response => {
        if (response.Clients) {
          const clients = this.$ensureArray(response.Clients.Client)
          for (const client of clients) {
            this.clients[client.UUID] = client.Name
          }
        }
      })
    },
    editLead(item) {
      this.$emit("selected", item)
    },
    refreshLeads() {
      this.loadLeads()
    },
    getRowClass(item) {
      const now = new Date()
      if (['lost', 'archive'].includes(item.lead.stage)) {
        return ""
      }

      if (new Date(item.lead.follow_up_date) < now) {
        return "leads-highlight-row"
      } else {
        return ""
      }
    },
  },
  computed: {
    stages: function () {
      const stages = {}
      for (const stage of leadStages) {
        stages[stage.value] = stage.text
      }
      return stages
    },
    staffOptions: function () {
      const options = []
      for (const staffUuid in this.staff) {
        const staffName = this.staff[staffUuid]
        options.push({text: staffName, value: staffUuid})
      }
      return options
    },
    filteredLeads: function () {
      const filteredLeads = []
      const now = new Date()

      for (const lead of this.leads) {
        const created = new Date(lead.created)
        const closed = new Date(lead.closed)
        const filterCreatedFromDate = new Date(this.filterCreatedFromDate)
        const filterCreatedToDate = new Date(this.filterCreatedToDate)
        const filterClosedFromDate = new Date(this.filterClosedFromDate)
        const filterClosedToDate = new Date(this.filterClosedToDate)

        if (this.filter.includes("my-leads") && lead.owner_uuid !== this.$access.wfm_uuid) {
          continue
        }
        if (this.filter.includes("due") && (new Date(lead.follow_up_date) > now  || ['lost', 'archive'].includes(lead.stage))) {
          continue
        }
        if (this.filter.includes("prospects") && !lead.isProspect) {
          continue
        }

        if (this.ownerFilter.length > 0 && !this.ownerFilter.includes(lead.owner_uuid)) {
          continue
        }
        if (this.stageFilter.length > 0 && !this.stageFilter.includes(lead.stage)) {
          continue
        }
        if (this.clientTypeFilter.length > 0 && !this.clientTypeFilter.includes(lead.is_prospect)) {
          continue
        }
        if (this.filterCreatedFromDate && this.filterCreatedToDate) {
          if (created < filterCreatedFromDate || created > filterCreatedToDate) {
            continue
          }
        }
        if (this.filterClosedFromDate && this.filterClosedToDate) {
          if (closed < filterClosedFromDate || closed > filterClosedToDate) {
            continue
          }
        }
        if (this.searchFilter) {
          if (!lead.title.toLowerCase().includes(this.searchFilter.toLowerCase())) {
            continue
          }
        }
        filteredLeads.push(lead)
      }
      return filteredLeads
    },
    tableData: function () {
      const tableData = []
      for (const item of this.filteredLeads) {
        tableData.push({
          "leadId": item.lead_id,
          "title": item.title,
          "stage": this.stages[item.stage],
          "owner": this.staff[item.owner_uuid] || "",
          "quote": item.quote_uuid,
          "isProspect": item.is_prospect ? "Prospect" : "Existing",
          "client": this.clients[item.client_uuid],
          "created": formatDate(new Date(item.created), "-"),
          "closed": item.closed ? formatDate(new Date(item.closed), "-") : "",
          "lead": item,
        })
      }
      return tableData
    }
  },
  mounted() {
    Promise.all([
      this.loadStaff(),
      this.loadClients(),
      this.loadLeads(),
    ]).then(() => {
      this.isReady = true
    })
  },
}

</script>

<style>
.leads-highlight-row {
  background: pink !important;
}
</style>