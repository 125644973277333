<template>
  <div v-if="loaded">
    <h3>New Enquiries</h3>
    <v-chart class="chart" :option="chartOptionsEnquiries()" autoresize/>

    <h3>Value of Leads </h3>
    <v-chart class="chart" :option="chartOptionsInProgress()" autoresize/>

  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {BarChart, LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
} from "echarts/components"
import VChart from "vue-echarts"
import {formatDate} from "@/lib/dateTimeUtilities"


use([
  CanvasRenderer,
  LineChart,
  BarChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
])

export default {
  name: "LeadsKpiGraphs",
  components: {VChart},
  permissions: ["Read Timeseries"],
  computed: {
    snapshotDates() {
      const key = "Leads New Enquiries|prospect" // just pick one
      const data = this.data[key]
      const dates = []
      for (const row of data) {
        dates.push({text: formatDate(new Date(row.record_time), "-"), value: row.record_time})
      }
      return dates
    },
  },
  methods: {
    loadAll() {
      const stages = ["new", 'scope', 'bid', 'inactive']
      const promises = [
        this.loadTimeseries("Leads New Enquiries", "prospect"),
        this.loadTimeseries("Leads New Enquiries", "existing")
      ]

      for (const stage of stages) {
        promises.push(this.loadTimeseries("Leads Value In Stage", stage))
        promises.push(this.loadTimeseries("Leads Count In Stage", stage))
      }

      Promise.all(promises).then(() => {
        this.loaded = true
      })
    },
    loadTimeseries(seriesName, itemName) {
      const query = {
        series_name: seriesName,
        item_name: itemName,
      }
      const key = seriesName + "|" + itemName
      return this.$BwfApi.get("timeseries", query).then(response => {
        this.data[key] = response
      })
    },
    chartOptionsEnquiries() { // eg. datasetName = "Leads Open Quotes", itemName = "number"
      const datasetName = 'Leads New Enquiries'
      const seriesNames = ["prospect", 'existing']
      const seriesData = {
        prospect: [],
        existing: [],
      }
      for (const seriesName of seriesNames) {
        const key = datasetName + "|" + seriesName
        if (!this.data[key]) {
          console.log("Missing data for", key)
          return {}
        }
        const data = this.data[key]
        for (const row of data) {
          const d = new Date(row.record_time)
          d.setDate(1) // set to first of month
          seriesData[seriesName].push([d, Math.round(10 * row.value) / 10])
        }
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: [
          {
            name: "Prospect",
            type: "bar",
            data: seriesData.prospect,
            barWidth: 20,
          },
          {
            name: "Existing",
            type: "bar",
            barWidth: 20,
            data: seriesData.existing,
          },
        ]
      }
    },
    chartOptionsInProgress() {
      const datasetName = 'Leads Value In Stage'
      const seriesNames = ["new", 'scope', 'bid']
      const seriesData = {
        new: [],
        scope: [],
        bid: [],
      }
      for (const seriesName of seriesNames) {
        const key = datasetName + "|" + seriesName
        if (!this.data[key]) {
          console.log("Missing data for", key)
          return {}
        }
        const data = this.data[key]
        for (const row of data) {
          const d = new Date(row.record_time)
          d.setDate(1) // set to first of month
          seriesData[seriesName].push([d, Math.round(10 * row.value) / 10])
        }
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "${value}"
          }
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: [
          {
            name: "New",
            type: "bar",
            data: seriesData.new,
            barWidth: 17,
          },
          {
            name: "Scoped",
            type: "bar",
            data: seriesData.scope,
            barWidth: 17,
          },
             {
            name: "Bid",
            type: "bar",
            data: seriesData.bid,
            barWidth: 17,
          },
        ]
      }
    },
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      loaded: false,
      data: {},
    }
  }
}
</script>

<style scoped>
.chart {
  height: 600px;
}

</style>