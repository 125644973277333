<template>
  <div v-if="isReady && services.length > 0" class="wrapper" id="bar-scroll-wrapper">
    <div class="chart-area">
      <v-chart class="chart" :option="chartOptions" :style="{width:chartWidth+'px'}" :key="graphKey"/>
      <div class="add-button" v-if="!basic">
        <v-btn @click="addMonth" color="primary" class="btn" x-large fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <br/>
        <v-btn @click="removeMonth" class=" mt-5 btn" color="red" dark x-small fab>
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="control-area">
      <div class="scroll-controls" v-if="!basic">
        <div class="scroll-controls-headings text-right">
          <div v-for="service in services" v-bind:key="service.service_id" class="scroll-control-heading">
            {{ service.name }}
          </div>
          <div><strong>Labour Total</strong></div>
          <div><strong>Labour %</strong></div>

          <div class="section-break-sm">
            <strong>Billing type: </strong>
            <CustomField type="job" :typeUUID="job.ID" fieldUUID="9c483d6b-411b-4633-8edc-43e0535a2350" :view="true"
                         :edit="true"/>
          </div>

          <div class="section-break-sm">Forecast billing (%)<br/>
            <span class="smaller"> ({{ Math.round(cashflowCalculator.getInvoicedLabourPercentage()) }}% already invoiced)</span>
          </div>
          <div class="">Forecast labour income</div>

          <div class="mt-3">
            <div>Forecast materials<br/>
              <span class="smaller">({{ Math.round(cashflowCalculator.getInvoicedMaterialsPercentage()) }}% already invoiced)</span>
            </div>
            <div>Cost</div>
            <div>Income</div>
          </div>

          <div style="margin-bottom:24px">
            <div><strong>Forecast total income ($)</strong></div>
            <div><strong>Forecast total cost ($)</strong></div>
          </div>
        </div>

        <div v-for="(detail,monthStr) in data" v-bind:key="monthStr" :id="monthStr"
             :class="{'month-column':true, 'month-column-disabled': isHistorical(monthStr)}"
             :style="{ 'width': barWidth + 'px' }">
          <div v-for="service in services" v-bind:key="service.service_id" class="scroll-control">
            <ScrollControl v-model="data[monthStr][service.service_id]" :max="serviceEstimates[service.service_id]"
                           :color="getColor(service.service_id)" @input="changed" ref="scrollControlRef"
                           :disabled="!$access.permissions.includes('Modify Capacity Plan')"/>
          </div>
          <div class="month-total-labour">
            <div>
              <strong>{{ Math.round(cashflowCalculator.getLabourTotalHours(monthStr)) }}</strong>
            </div>
            <div>
              <strong>{{ Math.round(cashflowCalculator.getLabourHoursCumulativePercent(monthStr)) }}</strong>
            </div>

            <div v-if="!isHistorical(monthStr)" class="section-break">
              <ScrollControl v-model="forecast.labour[monthStr]" :max="100"
                             :min="cashflowCalculator.getMinLabourPercentage(monthStr)"
                             color="#efbf04" @input="changed" ref="scrollControlRef"
                             :disabled="!$access.permissions.includes('Modify Capacity Plan')"/>
            </div>

            <div v-if="!isHistorical(monthStr)">
              {{ cashflowCalculator.forecastLabourIncomeDollars(monthStr) | formatCurrency }}
            </div>
          </div>

          <div class="scroll-control" v-if="!isHistorical(monthStr)">

            <ScrollControl v-model="forecast.materials[monthStr]" :max="100"
                           :min="cashflowCalculator.getMinMaterialsPercentage(monthStr)"
                           color="#efbf04" @input="changed" ref="scrollControlRef"
                           :disabled="!$access.permissions.includes('Modify Capacity Plan')"/>
            <div>{{ cashflowCalculator.forecastMaterialsCostDollars(monthStr) | formatCurrency }}</div>
            <div>{{ cashflowCalculator.forecastMaterialsPriceDollars(monthStr) | formatCurrency }}</div>
          </div>

          <div class="month-total" v-if="!isHistorical(monthStr)">
            <strong>{{ cashflowCalculator.forecastTotalIncomeDollars(monthStr) | formatCurrency }}</strong><br/>
            <strong>{{ cashflowCalculator.forecastTotalCostDollars(monthStr) | formatCurrency }}</strong>
          </div>
        </div>
      </div>


      <div class="scroll-controls-totals" v-if="!basic">
        <div v-for="service in services" v-bind:key="service.service_id" class="scroll-controls-total">
        <span :style="{color:totalsColor( totals[service.service_id] ,serviceEstimates[service.service_id])}">
          {{ Math.round(totals[service.service_id]) }} / {{ Math.round(serviceEstimates[service.service_id]) }}
          </span> {{ serviceNames[service.service_id] }}
        </div>
        <div class="scroll-controls-grand-total">
        <span :style="{color:totalsColor(this.totalPlanned, this.totalEstimate)}">
          {{ totalPlanned }}/{{ totalEstimate }}</span> Total
          ({{ Math.round(100 * totalPlanned / totalEstimate) }}%)
        </div>
        <div class="section-break mt-6">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="autoLabourForecast" icon size="small" v-bind="attrs" v-on="on">
                <v-icon>mdi-auto-fix</v-icon>
              </v-btn>
            </template>
            <span>Auto Labour Forecast</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="manualEstimateDialog=true" icon size="small" v-bind="attrs" v-on="on">
                <v-icon>mdi-shovel</v-icon>
              </v-btn>
            </template>
            <span>Estimate Override</span>
          </v-tooltip>

        </div>
        <div class="scroll-controls-total-labour-dollars">
          {{ cashflowCalculator.labourTotalDollars() | formatCurrency }} /
          {{ cashflowCalculator.quotedLabourDollars | formatCurrency }}
        </div>
        <div class="scroll-controls-total-materials-income">
          {{ cashflowCalculator.materialsCostTotal() | formatCurrency }} /
          {{ cashflowCalculator.quotedMaterialsCost | formatCurrency }}
        </div>

        <div class="scroll-controls-total-materials-cost">
          {{ cashflowCalculator.materialsPriceTotal() | formatCurrency }} /
          {{ cashflowCalculator.quotedMaterialsPrice | formatCurrency }}
        </div>

      </div>
      <p>The grey area is showing the actual hours spent.<br/>
        The service totals are made up of actual hours for past months plus planned hours going forward.</p>
    </div>

    <v-dialog v-model="manualEstimateDialog" v-if="manualEstimateDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Manual Estimate
        </v-card-title>
        <v-card-text>
          <v-switch v-model="manualEstimate" label="Override Estimate"></v-switch>
          <div v-if="manualEstimate">
            <v-text-field v-model.number="manualLabourEstimate" label="Labour Estimate" style="width:150px"
                          prefix="$"></v-text-field>
            <v-text-field v-model.number="manualMaterialsCostEstimate" label="Materials (Cost) Estimate"
                          style="width:150px" prefix="$"></v-text-field>
            <v-text-field v-model.number="manualMaterialsPriceEstimate" label="Materials (Price) Estimate"
                          style="width:150px" prefix="$"></v-text-field>
          </div>
          <v-btn @click="updateEstimate" color="primary">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

  <div v-else>
    <p>This job does not contain any services which can be planned.</p>
  </div>

</template>

<script>

import ScrollControl from "@/components/toolkit/ScrollControl"
import {use} from "echarts/core"
import {colors} from "@/lib/constants"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  MarkAreaComponent
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"
import {formatMonth, toLocalString} from "@/lib/dateTimeUtilities"
import Cashflow from "@/lib/capacity/cashflow";
import Labour from "@/lib/invoice/labour"
import Costs from "@/lib/invoice/costs"
import CustomField from "@/components/toolkit/CustomField.vue";

use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  MarkAreaComponent
])

export default defineComponent({
  name: "BarScroll",
  components: {
    CustomField,
    ScrollControl,
    VChart
  },
  props: ["jobId", "services", "value", "forecastData", "expectedDueDate", "originalDueDate", "timeUsedThisJob", "basic"],
  permissions: [],
  computed: {
    chartOptions: function () {
      const serviceData = {}
      for (const service of this.services) {
        serviceData[service.service_id] = []
      }

      for (const monthStr in this.data) {
        for (const service_id in this.data[monthStr]) {
          if (service_id in serviceData) {
            if (this.data[monthStr][service_id]["isHistorical"]) {
              serviceData[service_id].push(this.data[monthStr][service_id]["used"])
            } else {
              serviceData[service_id].push(this.data[monthStr][service_id]["planned"])
            }
          }
        }
      }

      const xAxis = []
      for (const monthStr of Object.keys(this.data)) {
        xAxis.push(formatMonth(monthStr))
      }

      const series = []

      for (const service of this.services) {
        series.push({
          name: service.name,
          type: "bar",
          itemStyle: {color: colors[service.color_idx]},
          data: serviceData[service.service_id],
          stack: "stack1"
        })
      }

      if (series.length > 0) {
        series[0].markLine = {
          silent: true,
          lineStyle: {width: 3, type: "solid"},
          symbol: "none",
          label: {show: false},
          data: [
            {
              xAxis: formatMonth(this.originalDueDate),
              lineStyle: {color: "#f87979"},
              label: {formatter: "Agreed Due", show: true}
            },
            {
              xAxis: formatMonth(this.expectedDueDate),
              lineStyle: {color: "blue"},
              label: {formatter: "Expected Due", show: true}
            }
          ]
        }

        let startMarkArea = ""
        let endMarkArea = ""
        for (const monthStr of Object.keys(this.data)) {
          if (this.isHistorical(monthStr)) {
            if (!startMarkArea) {
              startMarkArea = formatMonth(monthStr)
            }
            endMarkArea = formatMonth(monthStr)
          }
        }
        series[0].markArea = {
          silent: true,
          itemStyle: {
            color: "#eee",
            opacity: 0.5
          },
          data: [[
            {xAxis: startMarkArea},
            {xAxis: endMarkArea}
          ]]
        }
      }

      return {
        animation: false,
        grid: {
          left: 30,
          top: 50,
          right: 0,
          bottom: 50
        },
        legend: {},
        xAxis: {
          type: "category",
          data: xAxis,
          axisLabel: {
            interval: 0,
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#666"
            }
          }
        },
        series: series
      }
    },
    chartWidth() {
      return (this.barWidth * Object.keys(this.data).length) + 21
    },
    totalPlanned() {
      const total = Object.values(this.totals).reduce((a, b) => a + b, 0)
      return Math.round(total)
    },
    totalEstimate() {
      const estimate = Object.values(this.serviceEstimates).reduce((a, b) => a + b, 0)
      return Math.round(estimate)
    },
  },
  methods: {
    reset() {
      // reset all scroll controls
      this.$refs.scrollControlRef.forEach(item => {
        item.reset()
      })
    },
    autoLabourForecast() {
      this.cashflowCalculator.autoLabourForecast()
      this.changed()
    },
    isHistorical(monthStr) {
      const parts = monthStr.split("-")
      const dataYear = parseInt(parts[0])
      const dataMonth = parseInt(parts[1])
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth() + 1
      const c = currentYear * 12 + currentMonth
      const d = dataYear * 12 + dataMonth
      return d < c
    },
    totalsColor(planned, estimated) {
      return planned > estimated ? "red" : "green"
    },
    addMonth() {
      this.$emit("changed", {month: 1})

      const monthStrings = Object.keys(this.data)
      const lastMonth = monthStrings[monthStrings.length - 1]
      const parts = lastMonth.split("-")
      let month = parseInt(parts[1])
      let year = parseInt(parts[0])
      month += 1
      if (month === 13) {
        month = 1
        year += 1
      }
      const newMonth = year.toString() + "-" + month.toString().padStart(2, "0")

      const template = {}
      for (const service of this.services) {
        template[service.service_id] = {planned: 0, used: 0, isHistorical: this.isHistorical(newMonth)}
      }
      this.forecast.labour[newMonth] = {planned: 0, used: null, isHistorical: this.isHistorical(newMonth)}
      this.forecast.materials[newMonth] = {planned: 0, used: null, isHistorical: this.isHistorical(newMonth)}
      // needs to be set above before calling getMinLabourPercentage
      this.forecast.labour[newMonth].planned = this.cashflowCalculator.getMinLabourPercentage(newMonth)
      this.forecast.materials[newMonth].planned = this.cashflowCalculator.getMinMaterialsPercentage(newMonth)

      this.$set(this.data, newMonth, template)
      this.graphKey = Math.random() // force graph to redraw
      this.$nextTick(() => {
        document.getElementById("bar-scroll-wrapper").scrollLeft = 10000 // scroll all the way to the right
      })
    },
    removeMonth() {
      const monthStrings = Object.keys(this.data)
      const lastMonth = monthStrings[monthStrings.length - 1]
      if (this.isHistorical(lastMonth)) { // can't remove historical months
        return
      }
      this.$emit("changed", {month: -1})
      this.$delete(this.data, lastMonth)
      this.graphKey = Math.random() // force graph to redraw
    },
    changed() {
      this.updateTotals()
      // filter out anything historical
      const futureLabour = {}
      const futureForecast = {}
      for (const monthStr in this.data) {
        futureLabour[monthStr] = {}
        futureForecast[monthStr] = {}

        // Labour
        const item = this.data[monthStr]
        for (const service_id in item) {
          futureLabour[monthStr][service_id] = 0
          if (!item[service_id]["isHistorical"]) {
            futureLabour[monthStr][service_id] = item[service_id]["planned"]
          }
        }

        // Forecast
        futureForecast[monthStr] = {
          labour: this.forecast.labour[monthStr].planned,
          materials: this.forecast.materials[monthStr].planned,
          labour_cost: 0,
          labour_income: this.cashflowCalculator.forecastLabourIncomeDollars(monthStr),
          materials_cost: this.cashflowCalculator.forecastMaterialsCostDollars(monthStr),
          materials_income: this.cashflowCalculator.forecastMaterialsPriceDollars(monthStr)
        }

        this.$emit("input", futureLabour)
        this.$emit("forecastChanged", futureForecast)
        this.$emit("changed")
      }
    },
    updateTotals() {
      let totals = {}
      for (const service of this.services) {
        totals[service.service_id] = 0
      }
      for (const monthStr in this.data) {
        const item = this.data[monthStr]
        for (const service_id in item) {
          if (item[service_id]["isHistorical"]) {
            totals[service_id] += item[service_id]["used"]
          } else {
            totals[service_id] += item[service_id]["planned"]
          }
        }
      }

      this.totals = totals

    },
    getColor(service_id) {
      for (const service of this.services) {
        if (service.service_id === parseInt(service_id)) {
          return colors[service.color_idx]
        }
      }
      return colors[0]
    },
    getLookbackQuery() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      return {"from": start, "to": end, "detailed": "true"}
    },
    async jobChanged() {
      this.isReady = false
      return this.$WfmApi.get("job.api/get/" + this.jobId).then(response => {
        this.job = response.Job
        this.quoteFound = !!this.job.ApprovedQuoteID
        this.labour.processWfmJob(this.job)
        this.costs.processWfmJob(this.job)

        return Promise.all([
          this.$BwfApi.get("job", {job_id: this.jobId}).then((response) => {
            if (response) {
              this.manualEstimate = response.manual_estimate
              this.manualLabourEstimate = response.manual_labour_estimate
              this.manualMaterialsCostEstimate = response.manual_materials_cost_estimate
              this.manualMaterialsPriceEstimate = response.manual_materials_price_estimate
            }
          }),
          this.$WfmApi.get("time.api/job/" + this.jobId).then(response => {
            this.labour.processWfmTime(response)
          }),
          this.$WfmApi.get("invoice.api/job/" + this.jobId, this.getLookbackQuery()).then(response => {
            this.labour.processWfmInvoice(response)
            this.costs.processWfmInvoice(response)
          }),
          this.$WfmApi.get("job.api/costs/" + this.jobId).then(response => {
            this.costs.processWfmCosts(response)
          }),
          this.$WfmApi.get("quote.api/get/" + this.job?.ApprovedQuoteUUID).then(response => {
            if (response.Quote) {
              this.costs.processWfmQuote(response)
              this.labour.processWfmQuote(response)
            }
          }),
        ]).then(() => {
          this.labour.recalculateOverall()
          this.costs.recalculateCosts('amount')
          this.cashflowCalculator.setForecast(this.forecast)
          this.cashflowCalculator.setLabourData(this.data)
          this.cashflowCalculator.setInvoicedLabourDollars(this.labour.getPreviouslyInvoicedDollars())
          this.cashflowCalculator.setInvoicedMaterialsCost(this.costs.toInvoiceDollarsCost)
          this.cashflowCalculator.setInvoicedMaterialsPrice(this.costs.invoicedDollars)

          if (this.manualEstimate) {
            this.cashflowCalculator.setQuotedLabourDollars(this.manualLabourEstimate)
            this.cashflowCalculator.setQuotedMaterialsCost(this.manualMaterialsCostEstimate)
            this.cashflowCalculator.setQuotedMaterialsPrice(this.manualMaterialsPriceEstimate)
          } else {
            this.cashflowCalculator.setQuotedLabourDollars(this.labour.getQuotedDollars())
            this.cashflowCalculator.setQuotedMaterialsCost(this.costs.quoteDollarsCost)
            this.cashflowCalculator.setQuotedMaterialsPrice(this.costs.quoteDollarsPrice)
          }
        })
      })
    },
    updateEstimate() {
      this.$BwfApi.post("job", {
        job_id: this.jobId,
        manual_estimate: this.manualEstimate,
        manual_labour_estimate: this.manualLabourEstimate,
        manual_materials_cost_estimate: this.manualMaterialsCostEstimate,
        manual_materials_price_estimate: this.manualMaterialsPriceEstimate,
      })
      this.manualEstimateDialog = false

      if (this.manualEstimate) {
        this.cashflowCalculator.setQuotedLabourDollars(this.manualLabourEstimate)
        this.cashflowCalculator.setQuotedMaterialsCost(this.manualMaterialsCostEstimate)
        this.cashflowCalculator.setQuotedMaterialsPrice(this.manualMaterialsPriceEstimate)
      } else {
        this.cashflowCalculator.setQuotedLabourDollars(this.labour.getQuotedDollars())
        this.cashflowCalculator.setQuotedMaterialsCost(this.costs.quoteDollarsCost)
        this.cashflowCalculator.setQuotedMaterialsPrice(this.costs.quoteDollarsPrice)
      }
      this.autoLabourForecast()
    },
  },
  async mounted() {
    const data = {}
    const forecastMaterials = {}
    const forecastLabour = {}
    let forecastExists = false
    const allMonthStrings = Object.keys(this.value).concat(Object.keys(this.forecastData))
    for (const monthStr of allMonthStrings) {
      if (monthStr in this.forecastData && (this.forecastData[monthStr].materials !== 0 || this.forecastData[monthStr].labour !== 0)) {
        forecastExists = true
      }

      forecastMaterials[monthStr] = {
        planned: (monthStr in this.forecastData) ? this.forecastData[monthStr].materials : 0,
        used: null,
        isHistorical: this.isHistorical(monthStr)
      }
      forecastLabour[monthStr] = {
        planned: (monthStr in this.forecastData) ? this.forecastData[monthStr].labour : 0,
        used: null,
        isHistorical: this.isHistorical(monthStr)
      }
    }
    this.forecast = {
      materials: forecastMaterials,
      labour: forecastLabour
    }

    // labour
    for (const monthStr of allMonthStrings) {
      let monthData = this.value[monthStr] || {}
      for (const service of this.services) {
        const plannedValue = monthData[service.service_id] ? monthData[service.service_id] : 0

        let usedValue = 0
        if (this.isHistorical(monthStr)) { // replace planned value with actual value if time has passed
          if (monthStr in this.timeUsedThisJob && service.service_id in this.timeUsedThisJob[monthStr]) {
            usedValue = Math.round(10 * this.timeUsedThisJob[monthStr][service.service_id]) / 10
          }
        }
        data[monthStr] = data[monthStr] || {}
        data[monthStr][service.service_id] = data[monthStr][service.service_id] || {planned: 0, used: 0}
        data[monthStr][service.service_id]["planned"] = plannedValue
        data[monthStr][service.service_id]["used"] = usedValue
        data[monthStr][service.service_id]["isHistorical"] = this.isHistorical(monthStr)
      }
    }
    this.data = data

    this.serviceEstimates = {}
    for (const service of this.services) {
      this.serviceEstimates[service.service_id] = service.estimated
    }

    this.serviceNames = {}
    for (const service of this.services) {
      this.serviceNames[service.service_id] = service.name
    }
    this.updateTotals()
    await this.jobChanged()

    // if no forecast data exists, generate it
    if (!forecastExists) {
      console.log("No forecast data found, generating")
      this.autoLabourForecast()
    }

    this.isReady = true

    this.$nextTick(() => {
      // scroll so the current month is in view
      const now = new Date()
      const currentMonth = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0")
      const el = document.getElementById(currentMonth)
      if (el) {
        document.getElementById("bar-scroll-wrapper").scrollLeft = el.offsetLeft
      }

    })
  },
  data() {
    return {
      isReady: false,
      barWidth: 80,
      data: {},
      forecast: {materials: {}, labour: {}},
      serviceEstimates: {},
      serviceNames: {},
      totals: {},
      graphKey: Math.random(),
      manualEstimate: false, // if true, use manual estimates as opposed to the quote
      manualEstimateDialog: false, // if true, show the manual estimate dialog
      manualLabourEstimate: 0, // manual labour estimate
      manualMaterialsCostEstimate: 0, // manual materials estimate
      manualMaterialsPriceEstimate: 0, // manual materials estimate
      job: null,
      quoteFound: false,
      cashflowCalculator: new Cashflow(this),
      labour: new Labour(this, this.invoicePeriod, this.startDate, this.endDate),
      costs: new Costs(this, this.invoicePeriod, this.startDate, this.endDate),
    }
  }
})

</script>

<style scoped>
.chart-area {
  padding-left: 175px;
}

.month-column {
  display: inline-block;
  text-align: center;
  padding-top: 5px;
  vertical-align: top;
}

.month-column-disabled {
  background: #f6f6f6;
}

.scroll-control {
  padding: 5px 0;
}

.scroll-controls {
  padding-left: 25px;
  display: inline-block;
  vertical-align: top;
}

.scroll-controls-headings {
  display: inline-block;
  margin-top: 5px;
  background: white;
  width: 175px;
}

.scroll-controls-totals {
  display: inline-block;
  margin-top: 5px;
  position: absolute;
  background: white;
}

.scroll-controls-total {
  padding: 18px 0;
  vertical-align: top;
}

.scroll-controls-total-labour-dollars {
  padding-top: 15px;
  vertical-align: top;
}

.scroll-controls-total-materials-income {
  padding-top: 55px;
  vertical-align: top;
}

.scroll-controls-total-materials-cost {
  padding-top: 0;
  vertical-align: top;
}

.scroll-controls-grand-total {
  padding: 8px 0 0 0;
  vertical-align: top;
  font-weight: bold;
}

.month-total {

}

.chart {
  height: 300px;
  display: inline-block;
}

.wrapper {
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
}

.add-button {
  margin-top: 80px;
  margin-left: 50px;
  display: inline-block;
  vertical-align: top;
  text-align: center;

}

.btn {
  /*width: 180px;*/
}

.scroll-control-heading {
  padding: 20px 0;
}

.section-break {
  padding-top: 50px
}

.section-break-sm {
  padding-top: 11px
}

.smaller {
  font-size: 0.8em;
  line-height: 0.8em;
}

.month-total-labour {
  padding-top: 8px
}
</style>