<template>
  <v-container fluid v-if="isReady">
    <v-row>
      <v-col>
        <v-btn color="primary" class="ma-2 mt-8" @click="addLead">
          Create Lead
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>


    <div class="filter">
      <div class="strong">Filter</div>
      <v-checkbox label="My Leads" v-model="filters" value="my-leads"></v-checkbox>
      <v-checkbox label="Due" v-model="filters" value="due"></v-checkbox>
      <v-checkbox label="Prospects" v-model="filters" value="prospects"></v-checkbox>
    </div>

    <v-tabs v-model="tab" background-color="primary accent-4" centered dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-new" :class="overdueClass('new')">
        <div>New <span>({{ countByStage["new"] }})</span></div>
        <v-icon>mdi-phone</v-icon>
      </v-tab>

      <v-tab href="#tab-scope" :class="overdueClass('scope')">
        <div>Scope ({{ countByStage["scope"] }})</div>
        <v-icon>mdi-human-male-board-poll</v-icon>
      </v-tab>

      <v-tab href="#tab-bid" :class="overdueClass('bid')">
        <div>Bid ({{ countByStage["bid"] }})</div>
        <v-icon>mdi-account-box</v-icon>
      </v-tab>

      <v-tab href="#tab-inactive" :class="overdueClass('inactive')">
        <div>Inactive ({{ countByStage["inactive"] }})</div>
        <v-icon>mdi-pause</v-icon>
      </v-tab>

      <v-tab href="#tab-won">
        <div>Won ({{ countByStage["won"] }})</div>
        <v-icon>mdi-thumb-up</v-icon>
      </v-tab>

      <v-tab href="#tab-lost">
        <div>Lost ({{ countByStage["lost"] }})</div>
        <v-icon>mdi-thumb-down</v-icon>
      </v-tab>

      <v-tab href="#tab-archive">
        <div>Archive ({{ countByStage["archive"] }})</div>
        <v-icon>mdi-archive</v-icon>
      </v-tab>

      <v-tab href="#tab-all">
        <div>All ({{ countAll }})</div>
        <v-icon>mdi-account-box-multiple</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-new">
        <div v-for="(lead, idx) in filteredLeads" :key="idx" class="display-inline">
          <LeadsCard :lead="lead" @selected="selectLead" v-if="lead.stage==='new'" @input="leadUpdated"></LeadsCard>
        </div>

        <div v-if="countByStage['new']===0" class="text-center">
          No Leads
        </div>
      </v-tab-item>

      <v-tab-item value="tab-scope">
        <div v-for="(lead, idx) in filteredLeads" :key="idx" class="display-inline">
          <LeadsCard :lead="lead" @selected="selectLead" v-if="lead.stage==='scope'" @input="leadUpdated"></LeadsCard>
        </div>

        <div v-if="countByStage['scope']===0" class="text-center">
          No Leads
        </div>
      </v-tab-item>

      <v-tab-item value="tab-bid">
        <div v-for="(lead, idx) in filteredLeads" :key="idx" class="display-inline">
          <LeadsCard :lead="lead" @selected="selectLead" v-if="lead.stage==='bid'" @input="leadUpdated"></LeadsCard>
        </div>

        <div v-if="countByStage['bid']===0" class="text-center">
          No Leads
        </div>
      </v-tab-item>

      <v-tab-item value="tab-inactive">
        <LeadsList stage="inactive" :filter="filters" @selected="selectLead" ref="inactive-list"></LeadsList>
      </v-tab-item>

      <v-tab-item value="tab-won">
        <LeadsList stage="won" :filter="filters" @selected="selectLead" ref="won-list"></LeadsList>
      </v-tab-item>

      <v-tab-item value="tab-lost">
        <LeadsList stage="lost" :filter="filters" @selected="selectLead" ref="lost-list"></LeadsList>
      </v-tab-item>

      <v-tab-item value="tab-archive">
        <LeadsList stage="archive" :filter="filters" @selected="selectLead" ref="archive-list"></LeadsList>
      </v-tab-item>

      <v-tab-item value="tab-all">
        <LeadsList stage="all" :filter="filters" @selected="selectLead" ref="all-list"></LeadsList>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="showEditPopup" fullscreen>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Lead Details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="showEditPopup = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <LeadForm v-if="selectedLead" v-model="selectedLead" :key="selectedLead.lead_id"
                  @input="leadUpdated"></LeadForm>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import LeadsCard from "@/components/leads/LeadsCard.vue"
import LeadsList from "@/components/leads/LeadsList.vue"
import LeadForm from "@/components/leads/LeadForm.vue"
import {getStaffList} from "@/lib/lookup"
import {formatDate, toLocalString} from "@/lib/dateTimeUtilities"
import {leadProbabilities, leadStages} from "@/lib/constants"

export default {
  components: {LeadForm, LeadsList, LeadsCard},
  permissions: [...LeadsCard.permissions, ...LeadsList.permissions, ...LeadForm.permissions],
  computed: {
    countByStage() {
      const counts = {}
      for (const stage of leadStages) {
        counts[stage.value] = 0
      }
      for (const lead of this.filteredLeads) {
        counts[lead.stage]++
      }
      return counts
    },
    countAll() {
      return this.filteredLeads.length
    },
    filteredLeads() {
      if (this.filters.length === 0) {
        return this.leads
      }

      const filteredLeads = []
      const now = new Date()
      for (const lead of this.leads) {
        if (this.filters.includes("my-leads") && lead.owner_uuid !== this.$access.wfm_uuid) {
          continue
        }
        if (this.filters.includes("due") && (new Date(lead.follow_up_date)) > now) {
          continue
        }
        if (this.filters.includes("prospects") && !lead.is_prospect) {
          continue
        }
        filteredLeads.push(lead)
      }
      return filteredLeads
    },
  },
  methods: {
    selectLead(lead) {
      // update URL query string
      if (!(this.$route.query.LeadId && parseInt(this.$route.query.LeadId) === lead.lead_id)) {
        this.$router.replace(this.$route.path + "?LeadId=" + lead.lead_id)
      }

      this.selectedLead = lead
      this.showEditPopup = true
    },
    overdueClass(stage) {
      const now = new Date()
      for (const lead of this.filteredLeads) {
        if (lead.stage === stage && (new Date(lead.follow_up_date)) < now) {
          return "highlight"
        }
      }
      return ""
    },
    loadAll() {
      Promise.all([
        this.loadLeadSteps(),
        this.loadStaff(),
        this.loadClients(),
        this.loadQuotes(),
      ]).then(() => {
        this.loadLeads().then(() => {
          // Navigate to the selected lead if one is provided in the query string
          if (this.$route.query.LeadId) {
            const leadId = parseInt(this.$route.query.LeadId)
            const selectedLead = this.leads.find(l => l.lead_id === leadId)
            this.selectLead(selectedLead)
          }

          this.isReady = true
        })
      })
    },
    loadStaff() {
      return new Promise((resolve, reject) => {
        getStaffList(this, (res) => {
          if (!res) {
            reject()
            return
          }

          this.staff = []
          for (const staff of res) {
            this.staff.push({text: staff.name, value: staff.wfm_userid})
          }
          resolve()
        })
      })
    },
    loadClients() {
      return this.$WfmApi.get("client.api/list").then(response => {
        if (response.Clients) {
          this.clients = this.$ensureArray(response.Clients.Client)
        }
      })
    },
    loadQuotes() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      this.startDate = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      this.endDate = toLocalString(endDate).substr(0, 10).replace(/-/g, "")

      const query = {"from": this.startDate, "to": this.endDate, "detailed": "false"}
      return this.$WfmApi.get("quote.api/list", query).then(response => {
        if (response.Quotes) {
          this.quotes = this.$ensureArray(response.Quotes.Quote)
        }
      })
    },
    loadLeads() {
      return this.$BwfApi.get("leads").then(response => {
        if (response) {
          this.leads = response
          for (const lead of this.leads) {
            lead.owner = this.staff.find(s => s.value === lead.owner_uuid)
            lead.client = this.clients.find(c => c.UUID === lead.client_uuid)
            if (lead.client) {
              const clientContacts = this.$ensureArray(lead.client.Contacts.Contact)
              lead.contact = clientContacts.find(c => c.UUID === lead.contact_uuid)
            }
            lead.quote = this.quotes.find(q => q.UUID === lead.quote_uuid)
            lead.acceptanceProbability = leadProbabilities.find(p => p.value === lead.probability)
          }
        }
      })
    },
    loadLeadSteps() {
      return this.$BwfApi.get("setting/lead_steps").then(response => {
        if (response) {
          this.leadSteps = response.lead_steps
        }
      })
    },
    addLead() {
      const defaultFollowUpDate = new Date()
      defaultFollowUpDate.setDate(defaultFollowUpDate.getDate() + 7)

      const defaultExpiryDate = new Date()
      defaultExpiryDate.setDate(defaultExpiryDate.getDate() + 90)

      this.selectedLead = {
        id: null,
        stage: "new",
        owner: null,
        client: null,
        contact: null,
        quote: null,
        budget: null,
        acceptanceProbability: null,
        sales_notes: "",
        follow_up_action: "Follow up",
        follow_up_date: formatDate(defaultFollowUpDate, "-"),
        expiry: formatDate(defaultExpiryDate, "-"),
        steps: this.leadSteps.map(s => {
          return {name: s, complete: 0}
        })
      }
      this.showEditPopup = true
    },
    leadUpdated() {
      this.showEditPopup = false
      this.loadLeads()
      if (this.$refs["all-list"]) this.$refs["all-list"].refreshLeads()
      if (this.$refs["won-list"]) this.$refs["won-list"].refreshLeads()
      if (this.$refs["inactive-list"]) this.$refs["inactive-list"].refreshLeads()
      if (this.$refs["lost-list"]) this.$refs["lost-list"].refreshLeads()
      if (this.$refs["archive-list"]) this.$refs["archive-list"].refreshLeads()
    }
  },
  data() {
    return {
      tab: "tab-new",
      isReady: false,
      selectedLead: null,
      showEditPopup: false,
      staff: [],
      clients: [],
      quotes: [],
      leads: [],
      leadSteps: [],
      filters: [],
    }
  },
  mounted() {
    this.loadAll()
  }
}

</script>

<style scoped>
.highlight {
  color: pink !important;
}

h6 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.display-inline {
  display: inline-block;
  vertical-align: top;
}

.filter div {
  display: inline-block;
  padding-right: 30px;
}

.strong {
  font-weight: bold;
}
</style>